<template>
  <div class="box">
<!--    <van-nav-bar-->
<!--      :title="title"-->
<!--      left-text=""-->
<!--      left-arrow-->
<!--      @click-left="onClickLeft"-->
<!--      style="margin-bottom: 10px;position: sticky;top: 0"-->
<!--      v-if="!navStatus"-->
<!--    />-->
    <template v-if="tableData.type === 'education'">
      <div class="header">
        <div class="header-title">
          <span class="list_tag_title_JX">进修</span>
          <span>
            <span v-if="tableData.createdByType === 'department'"
              >[{{ tableData.educationHospitalDepartment[0].name }}]</span
            >
            {{ tableData.title }}</span
          >
        </div>
        <div class="header_body">
          <div class="header_body_left">
            <div style="display: flex;flex-wrap: wrap;">
              <div class="list_tag_tag1" v-if="tableData.cycle">
                {{ tableData.cycle }}
              </div>
              <div class="list_tag_tag1" v-if="tableData.education">
                {{ tableData.education }}
              </div>
              <div class="list_tag_tag1" v-if="tableData.stay">
                {{ tableData.stay }}
              </div>
            </div>
            <div class="list_tag_time">
              报名时间：{{
                moment(tableData.startTime).format("YYYY年MM月DD日")
              }}
              ~ {{ moment(tableData.endTime).format("YYYY年MM月DD日") }}
              <span
                :style="{
                  color:
                    computedTime(tableData) === '未开始'
                      ? '#0091FC'
                      : '#FD5F39',
                }"
                >{{ computedTime(tableData) }}</span
              >
            </div>
          </div>

          <div class="header_body_right">
            <div
              class="header_body_right_yiShou"
              @click="shouCang"
              v-if="tableData.isFollow === 1"
            >
              已收藏
            </div>
            <div class="header_body_right_button" @click="shouCang" v-else>
              <van-icon name="plus" />收藏
            </div>
          </div>
        </div>

        <div class="header_body_bottom" v-if="tableData.type === 'education'">
          <img :src="tableData.hospital.logo" style="width: 46px" />
          <div class="header_body_bottom_center">
            <div style="font-weight: 800;font-family: SimHei;">
              {{
                tableData.createdByType === "department"
                  ? tableData.educationHospitalDepartment[0].name
                  : tableData.hospital.name
              }}
            </div>
            <div style="font-size: 13px;color: #999999;margin-top: 3px">
              {{
                tableData.createdByType === "department"
                  ? tableData.hospital.name
                  : tableData.hospital.province +
                    tableData.hospital.city +
                    "&nbsp;&nbsp;|&nbsp;&nbsp;" +
                    tableData.hospital.level
              }}
            </div>
          </div>
        </div>
      </div>

      <div
        class="content"
        v-if="
          tableData.createdByType === 'hospital' &&
            tableData.childrenEducationRecruit.length
        "
      >
        <div
          style="display: flex;align-items: center;font-weight: 800;font-family: SimHei;"
        >
          <img :src="tagImga" style="width: 20px;" />
          <span style="margin-left: 5px">
            进修科室
          </span>
        </div>

        <div
          class="content_body"
          v-for="item in tableData.childrenEducationRecruit"
          :key="item.id"
        >
          <div class="content_body_title">
            {{ item.childHospitalDepartmentName }}
          </div>
          <div class="header_body_left">
            <div
              style="display: flex;flex-wrap: wrap;margin-left: 10px;padding-bottom: 10px"
            >
              <div class="list_tag_tag1" v-if="tableData.cycle">
                {{ tableData.cycle }}
              </div>
              <div class="list_tag_tag1" v-if="tableData.education">
                {{ tableData.education }}
              </div>
              <div class="list_tag_tag1" v-if="tableData.stay">
                {{ tableData.stay }}
              </div>
              <div
                class="list_tag_tag1"
                v-for="(tag, index) in item.tags"
                :key="index + '*'"
              >
                {{ tag }}
              </div>
            </div>
            <div style="margin-bottom: 10px;font-size: 13px;color: #999999">
              {{ tableData.notes }}
            </div>
          </div>
        </div>
      </div>

      <div class="body" style="padding: 0 20px 20px 20px">
        <div
          style="display: flex;align-items: center;font-weight: 800;font-family: SimHei;"
        >
          <img :src="tagImga" style="width: 20px;" />
          <span style="margin-left: 5px">
            进修内容
          </span>
        </div>
        <div v-html="tableData.introduce"></div>
      </div>
    </template>

    <template v-else-if="tableData.type === 'recruit'">
      <div class="header">
        <div class="header-title">
          <span class="list_tag_title_ZP">招聘</span>
          <span>
            <span>[{{ tableData.educationHospitalDepartment[0].name }}]</span>
            {{ tableData.title }}</span
          >
        </div>
        <div class="header_body">
          <div class="header_body_left">
            <div style="display: flex;flex-wrap: wrap;">
              <div class="list_tag_tag1" v-if="tableData.experience">
                {{ tableData.experience }}
              </div>
              <div class="list_tag_tag1" v-if="tableData.education">
                {{ tableData.education }}
              </div>
              <div class="list_tag_tag1" v-if="tableData.city">
                {{ tableData.city }}
              </div>
              <div
                class="list_tag_tag1"
                v-for="(tag, index) in tableData.tags"
                :key="index + '*'"
              >
                {{ tag }}
              </div>
            </div>
            <div class="list_tag_time">
              发布时间：{{ tableData.publishTime }}
            </div>
          </div>

          <div class="header_body_right">
            <div
              class="header_body_right_yiShou"
              @click="shouCang"
              v-if="tableData.isFollow === 1"
            >
              已收藏
            </div>
            <div class="header_body_right_button" @click="shouCang" v-else>
              <van-icon name="plus" />收藏
            </div>
          </div>
        </div>

        <div class="header_body_bottom">
          <img :src="tableData.hospital.logo" style="width: 46px" />
          <div class="header_body_bottom_center">
            <div style="font-weight: 800;font-family: SimHei;">
              {{ tableData.educationHospitalDepartment[0].name }}
            </div>
            <div style="font-size: 13px;color: #999999;margin-top: 3px">
              {{ tableData.hospital.name }}
            </div>
          </div>
        </div>
      </div>

      <div class="body" style="padding: 0 20px 20px 20px">
        <div
          style="display: flex;align-items: center;font-weight: 800;font-family: SimHei;"
        >
          <img :src="tagImga" style="width: 20px;" />
          <span style="margin-left: 5px">
            招聘内容
          </span>
        </div>
        <div v-html="tableData.introduce"></div>
      </div>
    </template>

    <template v-else-if="tableData.type === 'train'">
      <div class="header">
        <div class="header-title">
          <span class="list_tag_title_PX">培训</span>
          <span> {{ tableData.title }} </span>
        </div>
        <div class="header_body">
          <div class="header_body_left">
            <div style="display: flex;flex-wrap: wrap;">
              <div class="list_tag_tag1" v-if="tableData.cycle">
                {{ tableData.cycle }}
              </div>
              <div class="list_tag_tag1" v-if="tableData.trainingExpenses">
                {{ tableData.trainingExpenses }}
              </div>
              <div class="list_tag_tag1" v-if="tableData.stay">
                {{ tableData.stay }}
              </div>
              <div
                class="list_tag_tag1"
                v-for="(tag, index) in tableData.tags"
                :key="index + '*'"
              >
                {{ tag }}
              </div>
            </div>
            <div class="list_tag_time">
              报名时间：{{
                moment(tableData.startTime).format("YYYY年MM月DD日")
              }}
              ~ {{ moment(tableData.endTime).format("YYYY年MM月DD日") }}
              <span
                :style="{
                  color:
                    computedTime(tableData) === '未开始'
                      ? '#0091FC'
                      : '#FD5F39',
                }"
                >{{ computedTime(tableData) }}</span
              >
            </div>
          </div>

          <div class="header_body_right">
            <div
              class="header_body_right_yiShou"
              @click="shouCang"
              v-if="tableData.isFollow === 1"
            >
              已收藏
            </div>
            <div class="header_body_right_button" @click="shouCang" v-else>
              <van-icon name="plus" />收藏
            </div>
          </div>
        </div>

        <div class="header_body_bottom">
          <img :src="tableData.hospital.logo" style="width: 46px" />
          <div class="header_body_bottom_center">
            <div style="font-weight: 800;font-family: SimHei;">
              {{
                tableData.educationHospitalDepartment.length === 1
                  ? tableData.educationHospitalDepartment[0].name
                  : tableData.hospital.name
              }}
            </div>
            <div style="font-size: 13px;color: #999999;margin-top: 3px">
              {{
                tableData.educationHospitalDepartment.length > 1
                  ? tableData.hospital.province +
                    tableData.hospital.city +
                    "&nbsp;&nbsp;|&nbsp;&nbsp;" +
                    tableData.hospital.level
                  : tableData.hospital.name
              }}
            </div>
          </div>
        </div>
      </div>

      <div
        class="content"
        v-if="tableData.educationHospitalDepartment.length > 1"
      >
        <div
          style="display: flex;align-items: center;font-weight: 800;font-family: SimHei;"
        >
          <img :src="tagImga" style="width: 20px;" />
          <span style="margin-left: 5px">
            培训科室
          </span>
        </div>

        <div
          class="content_body"
          v-for="item in tableData.educationHospitalDepartment"
          :key="item.id"
        >
          <div class="content_body_title">
            {{ item.name }}
          </div>
          <!--          <div class="header_body_left">-->
          <!--            <div style="display: flex;flex-wrap: wrap;margin-left: 10px;padding-bottom: 10px">-->
          <!--              <div class="list_tag_tag1" v-if="tableData.cycle">-->
          <!--                {{tableData.cycle}}-->
          <!--              </div>-->
          <!--              <div class="list_tag_tag1" v-if="tableData.education">-->
          <!--                {{tableData.education}}-->
          <!--              </div>-->
          <!--              <div class="list_tag_tag1" v-if="tableData.stay">-->
          <!--                {{tableData.stay}}-->
          <!--              </div>-->
          <!--              <div class="list_tag_tag1" v-for="(tag, index) in item.tags" :key="index + '*'">-->
          <!--                {{tag}}-->
          <!--              </div>-->
          <!--            </div>-->
          <!--            <div style="margin-bottom: 10px;font-size: 13px;color: #999999">-->
          <!--              {{tableData.notes}}-->
          <!--            </div>-->
          <!--          </div>-->
        </div>
      </div>

      <div class="body" style="padding: 0 20px 20px 20px">
        <div
          style="display: flex;align-items: center;font-weight: 800;font-family: SimHei;"
        >
          <img :src="tagImga" style="width: 20px;" />
          <span style="margin-left: 5px">
            培训内容
          </span>
        </div>
        <div v-html="tableData.introduce"></div>
      </div>
    </template>
  </div>
</template>

<script>
import {
  getRecruitmentOneApi,
  getRecruitmentSHouCang,
  postRecruitmentQuXiaoSC,
} from "@/service/recruitmentApi";
import { getAdmins } from "@/utils/adminDate";
import { setById } from "@/service/base";
import moment from "moment/moment";
import { Toast } from "vant";
import tagImga from "@/assets/image/Recruitment/list.png";
export default {
  name: "Details",
  data() {
    return {
      title: "",
      moment,
      tagImga,
      tableData: {},
      navStatus: this.$router.currentRoute.query.navStatus
    }; //dataReturn
  },
  async created() {
    await this.test();
    await this.getTableData();
  },
  methods: {
    onClickLeft() {
      this.$router.back();
    },
    computedTime(item) {
      // 获取 item.endTime 对应的 Moment 对象
      const endTimeMoment = moment(item.endTime);

      // 获取当前日期的 Moment 对象，包括时间部分设置为0（确保计算包含今天）
      const todayStartMoment = moment().startOf("day");

      // 计算 endTime 和今天开始的时间点之间的差异，单位为天，+1 表示包含今天
      const day = endTimeMoment.diff(todayStartMoment, "days") + 1;
      const today = moment(); // 获取当前日期的 Moment 对象
      const isStart = moment(item.startTime).isAfter(today); // 比较 someDate 是否晚于今天
      if (isStart) {
        return "未开始";
      } else if (day > 10) {
        return "报名中";
      } else if (day <= 10) {
        return "剩余" + day + "天截止报名";
      }
    },
    async shouCang() {
      if (this.tableData.isFollow === 1) {
        const res = await postRecruitmentQuXiaoSC(this.tableData.otherId);
        if (res.code === 0) {
          Toast.success("已取消收藏");
          await this.getTableData();
        }
      } else {
        const data = {
          channel: this.$router.currentRoute.query.channel,
          contentId: this.tableData.otherId,
          contentType: "EDUCATION",
          userId: this.$router.currentRoute.query.memberId,
        };
        const res = await getRecruitmentSHouCang(data);
        if (res.code === 0) {
          Toast.success("收藏成功");
          await this.getTableData();
        }
      }
    },
    async test() {
      //像接口头部传用户信息
      let arrays = await getAdmins(
        this.$route.query.memberId,
        this.$route.query.channel
      );
      this.channel = arrays.os;
      this.version = arrays.version;
      this.ip = arrays.ip;
      await setById(arrays);
    },
    async getTableData() {
      const id = this.$router.currentRoute.query.id;
      const res = await getRecruitmentOneApi(id);
      if (res.code === 0) {
        this.tableData = res.data;
        document.title = this.tableData.title;
        this.title = this.tableData.title;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.header {
  box-shadow: 0 0 5px #eaeaea; /* 水平偏移 垂直偏移 模糊半径 阴影颜色 */
  margin: 0 20px 20px 20px;
  padding: 15px;
  border-radius: 10px;
}
.header-title {
}
.list_tag_title_JX {
  float: left;
  display: inline-block;
  margin: 2.5px 7px 0 0;
  width: 34px;
  height: 16px;
  font-family: SimHei;
  font-weight: 800;
  line-height: 16px;
  text-align: center;
  color: white;
  border-radius: 3px;
  background: linear-gradient(to left, #50e094, #16ba63);
  box-shadow: 0 0 5px 2px rgba(22, 186, 99, 0.3);
  font-size: 10px;
}
.list_tag_title_ZP {
  float: left;
  display: inline-block;
  margin: 2.5px 7px 0 0;
  width: 34px;
  height: 16px;
  font-family: SimHei;
  font-weight: 800;
  line-height: 16px;
  text-align: center;
  color: white;
  border-radius: 3px;
  box-shadow: 0 0 5px 2px rgba(253, 151, 179, 0.3);
  background: linear-gradient(to left, #ffc3b7, #fd97b0);
  font-size: 10px;
}
.list_tag_title_PX {
  float: left;
  display: inline-block;
  margin: 2.5px 7px 0 0;
  width: 34px;
  height: 16px;
  font-family: SimHei;
  font-weight: 800;
  line-height: 16px;
  text-align: center;
  color: white;
  border-radius: 3px;
  box-shadow: 0 0 5px 2px rgba(239, 187, 106, 0.3);
  background: linear-gradient(to left, #ffd595, #efbb6a);
  font-size: 10px;
}
.list_tag_tag1 {
  margin-right: 10px;
  padding: 3px 7px;
  color: #666666;
  font-size: 12px;
  background-color: #F2F2F2;
  margin-top: 7px;
  border-radius: 2px;
}
.list_tag_time {
  color: #999999;
  font-size: 10px;
  padding-top: 5px;
}
.header_body {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #eaeaea;
  padding-bottom: 10px;
  .header_body_left {
    width: 80%;
  }
  .header_body_right {
    .header_body_right_yiShou {
      color: white;
      background-color: #199efd;
      padding: 4px;
      font-size: 13px;
      margin-top: 5px;
      border-radius: 4px;
    }
    .header_body_right_button {
      color: #1999f5;
      border: 1px solid #1999f5;
      padding: 2px;
      font-size: 14px;
      margin-top: 5px;
      border-radius: 4px;
    }
  }
}
.header_body_bottom {
  display: flex;
  margin-top: 10px;
  align-items: center;
}
.header_body_bottom_center {
  margin-left: 10px;
}
.content {
  margin: 20px;
}
.content_body {
  margin-top: 15px;
  overflow: hidden;
  border-radius: 5px;
  box-shadow: 0 0 5px #eaeaea; /* 水平偏移 垂直偏移 模糊半径 阴影颜色 */
}
.content_body_title {
  padding: 10px;
  border-left: 6px solid #17bc65;
}
::v-deep .van-nav-bar .van-icon {
  color: #999999;
}
</style>
